<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>{{ $store.state.sysenv.codePropagated.parentName }}</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            class="chart"
            hide-actions
            :headers="headers"
            :items="intdgItems"
            :search="search"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center">{{ props.item.no }}</td>
                <td style="text-align: center">{{ props.item.idx }} / {{ props.item.navIdx }}</td>
                <td v-if="!phaseAppreared">{{ props.item.phaseName }}</td>
                <td v-if="!phaseAppreared">{{ props.item.groupName }}</td>
                <td>{{ props.item.itemName }}</td>
                <td>{{ props.item.subItemName }}</td>
                <td>{{ props.item.name }}</td>
                <td style="text-align: center">{{ props.item.version }}</td>
                <td style="text-align: center">{{ props.item.catCode }}</td>
                <td style="text-align: center">{{ props.item.typeCode }}</td>
                <td style="text-align: center">{{ props.item.directAccess == 'Y' ? 'Table/View' : 'Query/Procedure' }}</td>
                <td>{{ props.item.dbName }}</td>
                <td>{{ `${props.item.schemaName?props.item.schemaName+'.':''}${props.item.tableName}` }}</td>
                <td>{{ props.item.masterDesc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <form-modal 
        v-model="formOpened" 
        :item-key="itemKey"
        @updated="onUpdated" />
    </div>
  </div>
</template>

<script>
import { PageComponentService } from "@/services"
import FormModal from "./modal/SysenvPageDatatable.modal"

export default {
  name: "sysenv-intended-datagrid",
  components: {
    FormModal
  },
  data: () => ({
    pageComponentService: null,

    itemKey: Number,
    formOpened: false,
    search: '',
    selected: [],
    selectedItem: {},
    selectedPrevItems: [],
    intdgItems: []
  }),
  watch: {
    codePropagated: {
      handler(val) {
        this.getDatatables()
      },
      deep: true
    }
  },
  created() {
    this.pageComponentService = new PageComponentService()
    // this.getDatatables()
  },
  computed: {
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(val) { this.$store.commit("sysenv/codePropagate", val) }
    },
    headers() {
      let headers_ = [
        { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 40, },
        { type: 'text', text: 'IDX', align: 'center', value: 'idx', sortable: false, width: 80, },
      ]
      if(!this.phaseAppreared) headers_ = [
        ...headers_,
        { type: 'text', text: 'Phase', value: 'phaseName', sortable: false, width: 150, },
        { type: 'text', text: 'Menu Group', value: 'groupName', sortable: false, width: 180, },
      ]
      headers_ = [
        ...headers_,
        { type: 'text', text: 'Menu Item', align: 'left', value: 'itemName', sortable: false, width: 210, },
        { type: 'text', text: 'Sub-Item', align: 'left', value: 'subItemName', sortable: false, width: 150, },
        { type: 'text', text: 'Title', align: 'left', value: 'name', sortable: false, width: 220, },
        { type: 'text', text: 'Version Num', align: 'center', value: 'version', sortable: false, width: 80, },
        { type: 'text', text: 'Type', align: 'center', value: 'catCode', sortable: false, width: 70, },
        { type: 'text', text: 'Style', align: 'center', value: 'typeCode', sortable: false, width: 85, },
        { type: 'text', text: 'Access', align: 'center', value: 'directAccess', sortable: false, width: 120, },
        { type: 'text', text: 'Database', align: 'left', value: 'dbName', sortable: false, width: 150, },
        { type: 'text', text: 'Table/View', align: 'left', value: 'tableName', sortable: false, width: 280, },
        { type: 'text', text: 'Desciption', align: 'left', value: 'desc', sortable: false },
      ]
      return headers_
    },
    phaseAppreared() { return this.codePropagated.parentCode != 'ALL'},
    itemAppreared() { return this.codePropagated.code != 'ALL'},
  },
  methods: {
    getDatatables() {
      // this.pageComponentService.getCustomCompEnvs(
      //   this.codePropagated.parentCode,
      //   this.codePropagated.groupCode,
      //   this.codePropagated.code,
      //   'ALL',
      //   this.setIntendedDatagrid,
      // )
    },
    setIntendedDatagrid(res) {
      if (!res) res = []
      this.intdgItems = res
    },
    setAdd() {
      this.selectedItem = {}
      this.selectedItem.idx = ""
      this.itemKey = null
      this.formOpened = true
    },
    propStatus(props) {
      this.selectedItem = Object.assign({}, props.item)
      this.itemKey = this.selectedItem.idx

      if (this.selectedPrevItems.length > 0) {
        if (this.selectedPrevItems[0].idx == props.item.idx) {
          this.formOpened = true
          return
        } else {
          let _selected = props.item
          this.selected = [_selected]
        }
      } else {
        props.selected = !props.selected
      }

      this.selectedPrevItems = [...this.selected]
      this.formOpened = true
    },
    onUpdated() {
      this.getDatatables()
    }
  },
}
</script>
